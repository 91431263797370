<template>
  <nav>
    <div class="container flex flex-wrap items-center justify-between mx-auto">
      <NuxtLink to="/" class="py-2 flex items-center">
        <img id="logo" alt="logo Omgevingsdienst Midden- en West-Brabant, ga naar de homepage" src="/images/logo.png"/>
      </NuxtLink>
      <div id="navbar-default" class="hidden w-full md:block md:w-auto">
        <!--        <ul class="flex items-center justify-center w-full h-16 text-black md:space-x-16 dark:text-white">
          <li>
            <NuxtLink to="/nieuweopdracht">Start nieuwe opdracht</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/formulier">Formulier</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/example2">Example 2</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/example3">Example 3</NuxtLink>
          </li>
        </ul>-->
      </div>
    </div>
  </nav>
</template>